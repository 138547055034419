/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncidentStatus } from './incidentStatus';
import { Activity } from './activity';
import { OrderFeedBackType } from './orderFeedBackType';


export interface OrderFeedbackTO { 
    id?: number;
    orderUuid?: string;
    type?: OrderFeedBackType;
    externalNotificationId?: string;
    externalWfmId?: string;
    plannedProcessingDateUtc?: string;
    worker?: string;
    workerEffort?: string;
    workCenter?: string;
    feedbackText?: string;
    orderText?: string;
    protocolLampDefect?: boolean;
    protocolActivityDone?: boolean;
    protocolActivity?: Set<Activity>;
    protocolActivityOther?: string;
    protocolLink?: string;
    protocolLinkContent?: string;
    protocolText?: string;
    usedMaterials?: Array<string>;
    furtherBilling?: string;
    confirmationType?: string;
    confirmationTypeText?: string;
    confirmationDamageEntry?: string;
    confirmationDamageEntryText?: string;
    confirmationCause?: string;
    confirmationCauseText?: string;
    executedAt?: string;
    receivedAt?: string;
    statusType?: string;
    statusCode?: string;
    statusMessage?: string;
    validated?: boolean;
    validatedBy?: string;
    validatedDate?: string;
    comment?: string;
    createdBy?: string;
    createdAtUtc?: string;
    temporaryIncidentStatus?: IncidentStatus;
}
export namespace OrderFeedbackTO {
}


