// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'lighton-fe-dev',
  authMode: 'EXTERNAL_MSAD',
  authDemoClientId: '5dlo0vo66vqdv20355me08l27g',
  authMsadClientId: 'logidjrq9d93pc9h7gbm01cap',
  supportEmail: 'support-strassenbeleuchtung@netze-bw.de',
  buildId: '1711360',
  buildNumber: '20250213.1',
  sourceBranchName: 'master',
  sourceVersion: 'a2380013a2d89afccad70493895d9bb1efd906b7',
  requestedFor: 'Sylwester Feduk',
  pipelineStartTime: '2025-02-13 13:01:29+01:00',
  xApiKey: 'GooRDiKrSj5eevcvjecCnaB3wTaVqllK3ShNF6rd',
  automatedLoginState: '104367d9-c05f-47b2-b99c-ee9331b8e198',

  incidentBasePath: 'https://api.dienstleistung-light-on-dev.enbw.cloud',
  incidentService: 'https://api.dienstleistung-light-on-dev.enbw.cloud',

  demoPoolUrl: 'https://dl-light-on-dev-demo.auth.eu-central-1.amazoncognito.com',
  msadPoolUrl: 'https://dl-light-on-develop.auth.eu-central-1.amazoncognito.com',

  lionBasePath: 'https://api.lion-dev.dienstleistungen.netze-bw.de',
  lionService: 'https://api.lion-dev.dienstleistungen.netze-bw.de',

  users: {
    lighton: 'bnmyxcv',
    koerker: '#happy',
    augstej: '#light',
    anselre: '#laterne',
  },
};
