/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Massnahmen zur Behebung der Störung
 */
export type Activity = 'LED_RENEW_BOARD' | 'LED_RENEW_DRIVER' | 'LAMP_RENEW' | 'BALLAST_RENEW' | 'IGNITION_RENEW' | 'FRAME_RENEW' | 'MAST_DOOR_RENEW' | 'LUMINAIRE_GLASS_RENEW' | 'LIGHT_FUSE_RENEW' | 'GRINDING_BOX_RENEW' | 'WIRE_BREAK_REPAIR' | 'SUPPLY_LINE_USL_ATTACH' | 'OTHER' | 'NO_INCIDENT' | 'TEMPORARY_SOLUTION_MOUNTED' | 'TEMPORARY_SOLUTION_DEMOUNTED' | 'INCIDENT_NOT_SOLVED' | 'LIGHT_MOUNTED' | 'LAMP_REPLACED';

export const Activity = {
    LedRenewBoard: 'LED_RENEW_BOARD' as Activity,
    LedRenewDriver: 'LED_RENEW_DRIVER' as Activity,
    LampRenew: 'LAMP_RENEW' as Activity,
    BallastRenew: 'BALLAST_RENEW' as Activity,
    IgnitionRenew: 'IGNITION_RENEW' as Activity,
    FrameRenew: 'FRAME_RENEW' as Activity,
    MastDoorRenew: 'MAST_DOOR_RENEW' as Activity,
    LuminaireGlassRenew: 'LUMINAIRE_GLASS_RENEW' as Activity,
    LightFuseRenew: 'LIGHT_FUSE_RENEW' as Activity,
    GrindingBoxRenew: 'GRINDING_BOX_RENEW' as Activity,
    WireBreakRepair: 'WIRE_BREAK_REPAIR' as Activity,
    SupplyLineUslAttach: 'SUPPLY_LINE_USL_ATTACH' as Activity,
    Other: 'OTHER' as Activity,
    NoIncident: 'NO_INCIDENT' as Activity,
    TemporarySolutionMounted: 'TEMPORARY_SOLUTION_MOUNTED' as Activity,
    TemporarySolutionDemounted: 'TEMPORARY_SOLUTION_DEMOUNTED' as Activity,
    IncidentNotSolved: 'INCIDENT_NOT_SOLVED' as Activity,
    LightMounted: 'LIGHT_MOUNTED' as Activity,
    LampReplaced: 'LAMP_REPLACED' as Activity
};

