/**
 * DiCon Incident Service API
 * API used by DiCon, LiOn and Buefo.
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: dienstleistung@netze-bw.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Daten der Störung für CSV Export
 */
export interface IncidentCsvExportTO { 
    /**
     * Ort der Störungsmeldung
     */
    Stadt: string;
    /**
     * Straße und Hasunnummer der Störungsmeldung
     */
    Adresse: string;
    /**
     * Zeitstempel der Erfassung als String Label
     */
    Zeitpunkt: string;
    /**
     * Symptpome der Störungsmeldung
     */
    Symptome: string;
    /**
     * weitere Anmerkungen zu den Symptpomen der Störungsmeldung
     */
    Anmerkungen_Symptome?: string;
    /**
     * weitere Anmerkungen zur Störungsmeldung
     */
    Anmerkungen?: string;
    /**
     * weitere Anmerkungen des Bürgers zur Störungsmeldung
     */
    Anmerkungen_des_Brgers?: string;
    /**
     * Leuchte mit Störungsmeldung
     */
    Leuchtstelle?: string;
    /**
     * Schaltschrank mit Störungsmeldung
     */
    Schaltstelle?: string;
    /**
     * Störung überfällig?
     */
    berfllig?: string;
    /**
     * Liegt eine Beschwerde der Kommune vor?
     */
    Beschwerde_der_Kommune?: string;
    /**
     * Zugeordnet an (Sachbearbeiter des Falls)
     */
    Zugeordnet?: string;
    /**
     * Zugeteilt an Abteilung
     */
    Zugeteilt?: string;
    /**
     * Durchgeführte Maßnahmen
     */
    Durchgefhrte_Manahmen?: string;
    /**
     * Liste aller Typen der Orders, die zu dem Incident gehören
     */
    Auftragsarten?: string;
    /**
     * Current incident status
     */
    Status_der_Strung: string;
}

